<template>
  <div class="actions">
    <a
      href="javascript:void(0)"
      class="btn btn-primary btn-sm"
      data-toggle="modal"
      data-target="#update-constante"
      @click.prevent="setSelected"
      v-if="canUpdate"
    >
      <i class="icofont icofont-pencil" />
    </a>
    <a
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="canDelete"
    >
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
import { DELETE_CONSTANTE } from '../../../graphql/identification';
  export default {
      props : {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
        ...mapMutations({
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        setSelected(){
            this.setSelectedObject(this.object)
        },
          deleting(){
              this.$apollo.mutate({
                  mutation: DELETE_CONSTANTE,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Constnte ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting constante ${this.object.uid}`)
                  }
                })
          }
      },
      computed: {
          ...mapGetters({
            is_super_admin: 'auth/is_super_admin',
            is_veto : 'auth/is_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
          }),
          canUpdate(){
            return this.is_super_admin || this.is_veto || this.is_assistant_veto_major
          },
          canDelete(){
            return this.is_super_admin || this.is_veto || this.is_assistant_veto_major
          }
      }
  
  }
  </script>
  
  <style>
  
  </style>